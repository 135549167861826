import React, { memo, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Flex, Panel, Row } from '@sdflc/ui';
import { MenuHeader, FoodMenu, DefaultLayout, PageLoading, BusinessHoursOverlay } from '../components';
import { withProviders } from '../hoc';
import { SquareUpContext } from '../contexts';

const MenuPage = memo((props) => {
  const { menu, loadingData, firstTimeLoading, error } = useContext(SquareUpContext);

  if (firstTimeLoading) {
    return <PageLoading />;
  }

  //console.log('===== MENU', menu);

  const hotDrinks = menu.blocks['Hot Drinks'];
  const specialtyDrinks = menu.blocks['Specialty Drinks'];
  const coldDrinks = menu.blocks['Cold Drinks'];
  const other = menu.blocks['Other'];

  return (
    <Fragment>
      <DefaultLayout title='Beverage Menu'>
        <MenuHeader />
        <Panel padding={'20px'}>
          {error ? <span>{JSON.stringify(error.message)}</span> : null}
          {!error ? (
            <Row alignItems='flex-start'>
              {/* <Panel width={'25%'}>
                <FoodMenu
                  menu={[hotDrinks]}
                  isLoading={loadingData}
                  firstTimeLoading={firstTimeLoading}
                  columnsQty={1}
                  categoryWidth='100%'
                  displayModifiers={false}
                />
              </Panel> */}
              <Panel width={'33%'}>
                <FoodMenu
                  menu={[specialtyDrinks]}
                  isLoading={loadingData}
                  firstTimeLoading={firstTimeLoading}
                  columnsQty={1}
                  categoryWidth='100%'
                  displayModifiers={false}
                  cupSizes={true}
                  hideCategoryTitle={true}
                />
              </Panel>
              <Panel width={'29%'} ml={'6%'} mr={'6%'}>
                <FoodMenu
                  menu={[coldDrinks]}
                  isLoading={loadingData}
                  firstTimeLoading={firstTimeLoading}
                  columnsQty={1}
                  categoryWidth='100%'
                  displayModifiers={false}
                  hideCategoryTitle={true}
                />
              </Panel>
              <Panel width={'33%'}>
                <FoodMenu
                  menu={[other]}
                  isLoading={loadingData}
                  firstTimeLoading={firstTimeLoading}
                  columnsQty={1}
                  categoryWidth='100%'
                  displayModifiers={false}
                  hideCategoryTitle={true}
                />
              </Panel>
            </Row>
          ) : null}
        </Panel>
        <BusinessHoursOverlay />
      </DefaultLayout>
    </Fragment>
  );
});

MenuPage.displayName = 'MenuPage';

MenuPage.propTypes = {};

MenuPage.defaultProps = {};

export default withProviders(MenuPage);
